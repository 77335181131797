define("ember-cli-raygun/initializers/ember-cli-raygun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(config) {
    var raygunConfig = config.raygun;

    if (!raygunConfig || !raygunConfig.apiKey) {
      (true && !(false) && Ember.assert("Make sure you set your Raygun API Key in config/environment.js!"));
    }

    if (raygunConfig.enabled) {
      var defaultCustomData = {
        environment: config.environment,
        appName: config.APP.name
      };
      var initOptions = {};

      if (raygunConfig.enablePulse) {
        initOptions.disablePulse = false;
      }

      if (raygunConfig.disableCrashReporting) {
        initOptions.disableErrorTracking = true;
      }

      if (raygunConfig.options) {
        initOptions = Ember.assign(initOptions, raygunConfig.options);
      }

      Raygun.init(raygunConfig.apiKey, initOptions, Ember.assign(defaultCustomData, raygunConfig.customData)).attach();
      Raygun.setVersion(config.APP.version);
      Raygun.saveIfOffline(raygunConfig.offlineEnabled);

      Ember.onerror = function (error) {
        // eslint-disable-next-line no-console
        console.info("Ember.onerror called");
        Raygun.send(error);
      };

      Ember.RSVP.on('error', function (error) {
        Raygun.send(error);
      });
      var defaultErrorHandler = Ember.Logger.error;

      Ember.Logger.error = function (message, cause, stack) {
        defaultErrorHandler(message, cause, stack);
        Raygun.send(new Error(message + " (" + cause + ")"), null, {
          cause: cause,
          stack: stack
        });
      };

      if (!raygunConfig.beQuiet) {
        // eslint-disable-next-line no-console
        console.info("Ember CLI Raygun Enabled and ready to report!");
      }
    } else {
      if (!raygunConfig.beQuiet) {
        // eslint-disable-next-line no-console
        console.info("FYI: Ember CLI Raygun is currently disabled, as config.raygun.enabled is false");
      }
    }
  }
});